import createTheme, { ThemeOptions } from "@mui/material/styles/createTheme";

export const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    allVariants: {
      color: "rgba(94,94,94,0.87)",
    },
  },
  palette: {
    primary: {
      main: "rgba(94,94,94,0.87)",
      contrastText: "rgba(255,255,255,0.87)",
    },
    secondary: {
      main: "rgba(94,94,94,0.87)",
    },
    text: {
      primary: "rgba(94,94,94,0.87)",
      secondary: "rgba(94,94,94,0.57)",
      disabled: "rgba(94,94,94,0.38)",
    },
  },
});

export const AdminTheme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    allVariants: {
      color: "rgba(94,94,94,0.87)",
    },
  },
  palette: {
    primary: {
      main: "#2E3B55",
      contrastText: "rgba(255,255,255,0.87)",
    },
    secondary: {
      main: "rgba(94,94,94,0.87)",
    },
    text: {
      primary: "rgba(94,94,94,0.87)",
      secondary: "rgba(94,94,94,0.57)",
      disabled: "rgba(94,94,94,0.38)",
    },
  },
});

export const handWriting = createTheme({
  typography: {
    fontFamily: ["Send Flowers", "cursive"].join(","),
  },
  palette: {
    primary: {
      main: "#2E3B55",
      contrastText: "rgba(255,255,255,0.87)",
    },
    secondary: {
      main: "#bddbe0",
    },
    text: {
      primary: "rgba(94,94,94,0.87)",
      secondary: "rgba(94,94,94,0.57)",
      disabled: "rgba(94,94,94,0.38)",
    },
  },
});
